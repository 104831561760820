<template>
  <!--tw-bg-base-100-->
  <div data-theme="treasurebox" class="tw-bg-base-100 tw-w-full tw-h-full">
    <AppLayout>
      <template #rightTop></template>
      <UIQuery :pending="isPending" :error="error">
        <slot />
      </UIQuery>
    </AppLayout>
  </div>
</template>
<script setup lang="ts">
const { isPending, error, refetch } = useUserInfo(true);
</script>
